import React, {useState, useEffect} from "react";
import Utils from "../utils";
import Markdown from "markdown-to-jsx";
import Code from "./code";
const Mark = ({ something }) => {
  // console.log(something);
  const file_name = Utils[something]?.codefragment;
  const [post, setPost] = useState("");
  useEffect(() => {
    import(`../md-data/${file_name}`)
      .then((res) => {
        fetch(res.default)
          .then((res) => res.text())
          .then((res) => setPost(res));
      })
      .catch((err) => console.log(err));
  });

  return (
    <div>
      <Markdown options={{ overrides: { Code: { component: Code } } }}>
        {post}
      </Markdown>
    </div>
  );
};

export default Mark;