import React, { useState } from "react";
import Tree from "../components/tree";
import Mark from "../components/mark";
import Navbar from "../components/Navbar";
function Documentation() {
  const [something, setSomething] = useState([]);

  return (
    <div>
      <Navbar />
      <div className="row">
        <div
          className="col-12 col-sm-4 col-md-2"
          style={{ backgroundColor: "#1c223a", minHeight: `900px` }}
        >
          <Tree something={something} setSomething={setSomething} />
        </div>
        <div
          className="col-12 col-sm-8 col-md-8"
          style={{ marginLeft: 15, marginTop: 15 }}
        >
          <Mark something={something} />
        </div>
      </div>
    </div>
  );
}

export default Documentation;
