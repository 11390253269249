import "./App.css";
import { Router, Location, Redirect } from "@reach/router";
import { createContext } from "react";
import { useState } from "react";
// import Home from "./pages/home";
import Documentation from "./pages/documentation";
import Login from "./pages/login";
export const ThemeContext = createContext();


const PosedRouter = ({ children }) => (
  <Location>
    {({ location }) => (
      <div id="routerhang">
        <div key={location.key}>
          <Router location={location}>{children}</Router>
        </div>
      </div>
    )}
  </Location>
);
function App() {
  const [isDark, setIsDark] = useState(true);

  const value = {
    isDark,
    setIsDark,
  };
  return (
    <ThemeContext.Provider value={value}>
      <PosedRouter>
        <Login exact path="/" >
          <Redirect to="/login" />
        </Login>
        <Documentation exact path="/documentation" />
      </PosedRouter>
    </ThemeContext.Provider>
  );
}

export default App;
