import { ThemeContext } from "../App";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useContext, useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter";
import {
  materialDark,
  materialLight,
} from "react-syntax-highlighter/dist/esm/styles/prism";
import jsx from "react-syntax-highlighter/dist/esm/languages/prism/jsx";
import prism from "react-syntax-highlighter/dist/esm/styles/prism/prism";

SyntaxHighlighter.registerLanguage("jsx", jsx);

const Code = ({ children, language }) => {
  // const [copied, setCopied] = useState(false);
  // const { isDark } = useContext(ThemeContext);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setCopied(false);
  //   }, 1000);
  //   return () => clearTimeout(timer);
  // }, [copied]);

  return (
    <div className="code">
      {/* <CopyToClipboard text={children} onCopy={() => setCopied(true)}>
        <button className="icon copy-icon">
          {copied ? <CheckCircleOutlineIcon /> : <CopyAllIcon />}
        </button>
      </CopyToClipboard> */}
      <SyntaxHighlighter
        language={language}
        style={materialDark}
      >
        {children}
      </SyntaxHighlighter>
    </div>
  );
};

export default Code;
