import React, { Component } from "react";
import "../assets/login.css";

class Login extends Component {
  handleSubmit = (e) => {
    e.preventDefault();
    console.log(e.target.email.value);

    if (!e.target.email.value) {
      alert("Email is required");
    } else if (!e.target.email.value) {
      alert("Valid email is required");
    } else if (!e.target.password.value) {
      alert("Password is required");
    } else if (
      e.target.email.value === "demo@codesplain.live" &&
      e.target.password.value === "codesplain"
    ) {
      alert("Successfully logged in");
      e.target.email.value = "";
      e.target.password.value = "";
      window.location = "/documentation";
    } else {
      alert("Wrong email or password combination");
    }
  };

  handleClick = (e) => {
    e.preventDefault();

    alert("Goes to registration page");
  };

  render() {
    const logo =
      "https://res.cloudinary.com/dajxe0hhu/image/upload/v1677403885/codesplain-website/codebook-logo-150_e6pjpp.png";
    return (
      <div className="vh-100 d-flex justify-content-center align-items-center bg-primary">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-12 col-md-8 col-lg-6">
              <div className="border border-3 border-primary"></div>
              <div className="card bg-white shadow-lg">
                <div className="card-body p-5">
                  <form className="mb-3 mt-md-4" onSubmit={this.handleSubmit}>
                    <img
                      className="mx-auto d-block"
                      src={logo}
                      alt="Logo"
                      width="35%"
                      height="36%"
                    />

                    <p className="text-center mt-3 mb-5">
                      Please enter your login and password!
                    </p>
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label ">
                        Email address
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="demo@codesplain.live"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="password" className="form-label ">
                        Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        placeholder="codesplain"
                      />
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                      <button
                        className="btn btn-outline-dark w-100"
                        type="submit"
                      >
                        Access Codesplain
                      </button>
                    </div>
                  </form>
                  <div>
                    <p className="mb-0 text-center">
                      Don't have an account?{" "}
                      <a
                        href="https://codesplain.live"
                        className="text-primary fw-bold"
                      >
                        Know More
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
