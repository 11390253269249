import React from "react";
// import "./home.css";

const Tree = ({ something, setSomething }) => {
  return (
    <div className="navtree">
      <div>
        <ul className="tree">
          <li>
            <details open>
              <summary className="codetitle">Ethereum Wallet</summary>

              <ul style={{ color: "white" }}>
                <li>
                  <details>
                    <summary>src</summary>
                    <ul>
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() => setSomething("01")}
                      >
                        hdkey.ts
                      </li>
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() => setSomething("02")}
                      >
                        index.ts
                      </li>
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() => setSomething("03")}
                      >
                        thirdparty.ts
                      </li>
                    </ul>
                  </details>
                </li>
                <li>
                  <details>
                    <summary>test</summary>
                    <ul>
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() => setSomething("04")}
                      >
                        hdkey.spec.ts
                      </li>
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() => setSomething("05")}
                      >
                        index.spec.ts
                      </li>
                    </ul>
                  </details>
                </li>
                <li>
                  <details>
                    <summary>ignored</summary>
                    <ul>
                      <li className="levelzero">.eslintrc.js</li>
                      <li className="levelzero">.gitignore</li>
                      <li className="levelzero">.prettierignore</li>
                      <li className="levelzero">CHANGELOG.md</li>
                      <li className="levelzero">karma.conf.js</li>
                      <li className="levelzero">LICENSE</li>
                      <li className="levelzero">package.json</li>
                      <li className="levelzero">package-lock.json</li>
                      <li className="levelzero">prettier.config.js</li>
                      <li className="levelzero">README.md</li>
                      <li className="levelzero">tsconfig.json</li>
                      <li className="levelzero">tsconfig.prod.json</li>
                    </ul>
                  </details>
                </li>
              </ul>
            </details>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Tree;
