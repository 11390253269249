const index = {
  "01": {
    codefragment: "hdkey.md",
  },
  "02": {
    codefragment: "index.md",
  },
  "03": {
    codefragment: "thirdparty.md",
  },
  "04": {
    title: "title 10",
    codefragment: "hdkeyspec.md",
  },
  "05": {
    title: "title 10",
    codefragment: "indexspec.md",
  },
};

export default index;
